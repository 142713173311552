<style scoped>
span {
  font-size: 15px;
}

@media print {
    .contentisi { page-break-before: always; } /* page-break-after works, as well */
}

</style>
<template>
  <title>Cetak Surat Jalan</title>
  <body>
    <div class="contentisi">
        <section class="content">
            <div class="row">
                <div class="col-md-8" style="margin-top:-25px">
                    <h3 style="font-size:25px"><b>PT MASTER KIDZ INDONESIA</b></h3>
                    <div style="margin-left:470px;margin-top:-50px"><h3 style="font-size:25px"><b>SURAT JALAN</b></h3></div>
                    <div style="margin-top:-10px"></div>
                    <span>Kawasan Industri Kendal, Jl Saptarengga No 12</span>
                    <div style="margin-left:485px;margin-top:-20px"><span style="font-size:20px;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman'"><i>Delivery Order</i></span></div><br>
                    <div style="margin-top:-10px"></div>
                    <span>Wonorejo, Kaliwungu - Kendal 51372 Jawa Tengah, INDONESIA</span>
                    <div style="margin-left:520px;margin-top:-25px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:-48px">No: </span></div><br>
                    <div style="margin-top:-20px"></div>
                    <span>Tel: 024-33000010</span>
                    <div style="margin-left:455px;margin-top:-20px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl Dok <i>Doc Date: </i> </span></div><br>
                    <div style="margin-top:15px"></div>
                    <span>Kepada <i>Deliver to:</i></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Plat #: </span><br></div><br>
                    <div style="margin-top:15px"></div>
                    <span><b>AGUS DEDY</b></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px"><i>Plate #: </i></span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Container #: </span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl & Waktu Keluar: </span><br></div><br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8" style="margin-left:-30px">
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table id="mydata" class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <td><center><b>Item</b></center></td>
                                                <td><center><b>Rincian</b><br>Description</center></td>
                                                <td><center><b>Kuantiti</b><br><span>Quantity</span></center> </td>
                                                <td><center><b>Satuan</b><br>Unit</center></td>
                                                <td><center><b>Jumlah</b><br>Total</center></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><center>1</center></td>
                                                <td>SCRAP POTONGAN KAYU</td>
                                                <td><center>1000</center></td>
                                                <td><center>KG</center></td>
                                                <td><center>1 TRUCK</center></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td><b style="float:right">Jumlah</b></td>
                                                <td><center><b>1000</b><br></center> </td>
                                                <td><center><b>KG</b></center></td>
                                                <td><center><b>1 TRUCK</b></center></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table class="table" border="2">
                                        <thead>
                                            <tr>
                                                <td>Disediakan, <br><i style="font-size:11px">Prepared by</i></td>
                                                <td>Menyetujui: <br><i style="font-size:11px">Agreed by</i></td>
                                                <td>Mengetahui: <br><i style="font-size:11px">Reviewed by</i></td>
                                                <td>SATPAM <br><i style="font-size:11px">Security Check</i></td>
                                                <td>Penerima <br><i style="font-size:11px">Received by</i></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="overflow: hidden;white-space: nowrap;height: 80px;border-collapse: collapse;"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>PIC</td>
                                                <td>Gudang &nbsp;&nbsp; Exim</td>
                                                <td>Manager</td>
                                                <td>Tgl & Waktu <i style="font-size:11px">Date & time</i> </td>
                                                <td>Nama & Tgl <i style="font-size:11px">Name & Date</i> </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div style="margin-top:-20px"></div>
                                    Salinan Kantor <i>Office Copy</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
    <div class="contentisi">
        <section class="content">
            <div class="row">
                <div class="col-md-8" style="margin-top:-25px">
                    <h3 style="font-size:25px"><b>PT MASTER KIDZ INDONESIA</b></h3>
                    <div style="margin-left:470px;margin-top:-50px"><h3 style="font-size:25px"><b>SURAT JALAN</b></h3></div>
                    <div style="margin-top:-10px"></div>
                    <span>Kawasan Industri Kendal, Jl Saptarengga No 12</span>
                    <div style="margin-left:485px;margin-top:-20px"><span style="font-size:20px;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman'"><i>Delivery Order</i></span></div><br>
                    <div style="margin-top:-10px"></div>
                    <span>Wonorejo, Kaliwungu - Kendal 51372 Jawa Tengah, INDONESIA</span>
                    <div style="margin-left:520px;margin-top:-25px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:-48px">No: </span></div><br>
                    <div style="margin-top:-20px"></div>
                    <span>Tel: 024-33000010</span>
                    <div style="margin-left:455px;margin-top:-20px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl Dok <i>Doc Date: </i> </span></div><br>
                    <div style="margin-top:15px"></div>
                    <span>Kepada <i>Deliver to:</i></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Plat #: </span><br></div><br>
                    <div style="margin-top:15px"></div>
                    <span><b>AGUS DEDY</b></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px"><i>Plate #: </i></span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Container #: </span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl & Waktu Keluar: </span><br></div><br>
                </div> 
            </div>
            <div class="row">
                <div class="col-md-8" style="margin-left:-30px">
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table id="mydata" class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <td><center><b>Item</b></center></td>
                                                <td><center><b>Rincian</b><br>Description</center></td>
                                                <td><center><b>Kuantiti</b><br><span>Quantity</span></center> </td>
                                                <td><center><b>Satuan</b><br>Unit</center></td>
                                                <td><center><b>Jumlah</b><br>Total</center></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><center>1</center></td>
                                                <td>SCRAP POTONGAN KAYU</td>
                                                <td><center>1000</center></td>
                                                <td><center>KG</center></td>
                                                <td><center>1 TRUCK</center></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td><b style="float:right">Jumlah</b></td>
                                                <td><center><b>1000</b><br></center> </td>
                                                <td><center><b>KG</b></center></td>
                                                <td><center><b>1 TRUCK</b></center></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table class="table" border="2">
                                        <thead>
                                            <tr>
                                                <td>Disediakan, <br><i style="font-size:11px">Prepared by</i></td>
                                                <td>Menyetujui: <br><i style="font-size:11px">Agreed by</i></td>
                                                <td>Mengetahui: <br><i style="font-size:11px">Reviewed by</i></td>
                                                <td>SATPAM <br><i style="font-size:11px">Security Check</i></td>
                                                <td>Penerima <br><i style="font-size:11px">Received by</i></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="overflow: hidden;white-space: nowrap;height: 80px;border-collapse: collapse;"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>PIC</td>
                                                <td>Gudang &nbsp;&nbsp; Exim</td>
                                                <td>Manager</td>
                                                <td>Tgl & Waktu <i style="font-size:11px">Date & time</i> </td>
                                                <td>Nama & Tgl <i style="font-size:11px">Name & Date</i> </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div style="margin-top:-20px"></div>
                                    Salinan Supir <i>Driver's Copy</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="contentisi">
        <section class="content">
            <div class="row">
                <div class="col-md-8" style="margin-top:-25px">
                    <h3 style="font-size:25px"><b>PT MASTER KIDZ INDONESIA</b></h3>
                    <div style="margin-left:470px;margin-top:-50px"><h3 style="font-size:25px"><b>SURAT JALAN</b></h3></div>
                    <div style="margin-top:-10px"></div>
                    <span>Kawasan Industri Kendal, Jl Saptarengga No 12</span>
                    <div style="margin-left:485px;margin-top:-20px"><span style="font-size:20px;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman'"><i>Delivery Order</i></span></div><br>
                    <div style="margin-top:-10px"></div>
                    <span>Wonorejo, Kaliwungu - Kendal 51372 Jawa Tengah, INDONESIA</span>
                    <div style="margin-left:520px;margin-top:-25px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:-48px">No: </span></div><br>
                    <div style="margin-top:-20px"></div>
                    <span>Tel: 024-33000010</span>
                    <div style="margin-left:455px;margin-top:-20px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl Dok <i>Doc Date: </i> </span></div><br>
                    <div style="margin-top:15px"></div>
                    <span>Kepada <i>Deliver to:</i></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Plat #: </span><br></div><br>
                    <div style="margin-top:15px"></div>
                    <span><b>AGUS DEDY</b></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px"><i>Plate #: </i></span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Container #: </span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl & Waktu Keluar: </span><br></div><br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8" style="margin-left:-30px">
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table id="mydata" class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <td><center><b>Item</b></center></td>
                                                <td><center><b>Rincian</b><br>Description</center></td>
                                                <td><center><b>Kuantiti</b><br><span>Quantity</span></center> </td>
                                                <td><center><b>Satuan</b><br>Unit</center></td>
                                                <td><center><b>Jumlah</b><br>Total</center></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><center>1</center></td>
                                                <td>SCRAP POTONGAN KAYU</td>
                                                <td><center>1000</center></td>
                                                <td><center>KG</center></td>
                                                <td><center>1 TRUCK</center></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td><b style="float:right">Jumlah</b></td>
                                                <td><center><b>1000</b><br></center> </td>
                                                <td><center><b>KG</b></center></td>
                                                <td><center><b>1 TRUCK</b></center></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table class="table" border="2">
                                        <thead>
                                            <tr>
                                                <td>Disediakan, <br><i style="font-size:11px">Prepared by</i></td>
                                                <td>Menyetujui: <br><i style="font-size:11px">Agreed by</i></td>
                                                <td>Mengetahui: <br><i style="font-size:11px">Reviewed by</i></td>
                                                <td>SATPAM <br><i style="font-size:11px">Security Check</i></td>
                                                <td>Penerima <br><i style="font-size:11px">Received by</i></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="overflow: hidden;white-space: nowrap;height: 80px;border-collapse: collapse;"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>PIC</td>
                                                <td>Gudang &nbsp;&nbsp; Exim</td>
                                                <td>Manager</td>
                                                <td>Tgl & Waktu <i style="font-size:11px">Date & time</i> </td>
                                                <td>Nama & Tgl <i style="font-size:11px">Name & Date</i> </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div style="margin-top:-20px"></div>
                                    Salinan Beacukai <i>Custom Copy</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="contentisi">
        <section class="content">
            <div class="row">
                <div class="col-md-8" style="margin-top:-25px">
                    <h3 style="font-size:25px"><b>PT MASTER KIDZ INDONESIA</b></h3>
                    <div style="margin-left:470px;margin-top:-50px"><h3 style="font-size:25px"><b>SURAT JALAN</b></h3></div>
                    <div style="margin-top:-10px"></div>
                    <span>Kawasan Industri Kendal, Jl Saptarengga No 12</span>
                    <div style="margin-left:485px;margin-top:-20px"><span style="font-size:20px;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman'"><i>Delivery Order</i></span></div><br>
                    <div style="margin-top:-10px"></div>
                    <span>Wonorejo, Kaliwungu - Kendal 51372 Jawa Tengah, INDONESIA</span>
                    <div style="margin-left:520px;margin-top:-25px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:-48px">No: </span></div><br>
                    <div style="margin-top:-20px"></div>
                    <span>Tel: 024-33000010</span>
                    <div style="margin-left:455px;margin-top:-20px"><span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl Dok <i>Doc Date: </i> </span></div><br>
                    <div style="margin-top:15px"></div>
                    <span>Kepada <i>Deliver to:</i></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Plat #: </span><br></div><br>
                    <div style="margin-top:15px"></div>
                    <span><b>AGUS DEDY</b></span>
                    <div style="margin-left:455px;margin-top:-55px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px"><i>Plate #: </i></span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Container #: </span><br></div><br>
                    <div style="margin-left:455px;margin-top:-15px"> <span style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';margin-left:16px">Tgl & Waktu Keluar: </span><br></div><br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8" style="margin-left:-30px">
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table id="mydata" class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <td><center><b>Item</b></center></td>
                                                <td><center><b>Rincian</b><br>Description</center></td>
                                                <td><center><b>Kuantiti</b><br><span>Quantity</span></center> </td>
                                                <td><center><b>Satuan</b><br>Unit</center></td>
                                                <td><center><b>Jumlah</b><br>Total</center></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><center>1</center></td>
                                                <td>SCRAP POTONGAN KAYU</td>
                                                <td><center>1000</center></td>
                                                <td><center>KG</center></td>
                                                <td><center>1 TRUCK</center></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td><b style="float:right">Jumlah</b></td>
                                                <td><center><b>1000</b><br></center> </td>
                                                <td><center><b>KG</b></center></td>
                                                <td><center><b>1 TRUCK</b></center></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-header">
                        <div class="box-header with-border">
                            <div class="box-body">
                                <div class="table-responsive">
                                    <table class="table" border="2">
                                        <thead>
                                            <tr>
                                                <td>Disediakan, <br><i style="font-size:11px">Prepared by</i></td>
                                                <td>Menyetujui: <br><i style="font-size:11px">Agreed by</i></td>
                                                <td>Mengetahui: <br><i style="font-size:11px">Reviewed by</i></td>
                                                <td>SATPAM <br><i style="font-size:11px">Security Check</i></td>
                                                <td>Penerima <br><i style="font-size:11px">Received by</i></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="overflow: hidden;white-space: nowrap;height: 80px;border-collapse: collapse;"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>PIC</td>
                                                <td>Gudang &nbsp;&nbsp; Exim</td>
                                                <td>Manager</td>
                                                <td>Tgl & Waktu <i style="font-size:11px">Date & time</i> </td>
                                                <td>Nama & Tgl <i style="font-size:11px">Name & Date</i> </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div style="margin-top:-20px"></div>
                                    Salinan Vendor <i>Vendor's Copy</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- <button @click="klipdf()" id="cmd">Generate PDF</button> -->
  </body>
</template>

<script>
export default {
  name: "cetak",
  methods: {
    klipdf() {
      alert("test");
    }
  }
};
</script>
